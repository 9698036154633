<template>
  <div></div>
</template>

<script>
import { usergGtById } from "@/service/api/index.js";

export default {
  name: "webSocket",
  data() {
    return {
      // wsUrl: "wss://api.freshph.online:29000/socket/web/notify/{userId}/pay/wx",
      websocket: null,
      info: ""
    };
  },
  mounted() {
    usergGtById().then(res => {
      this.info = res.data;
    });
  },
  computed: {
    wsUrl() {
      return `wss://api.freshph.online:29000/socket/web/notify/${this.info.id}/pay/wx`;
    }
  },
  methods: {
    webSocketLink() {
      if (!this.websocket) {
        let _this = this;
        var heartCheck = {
          timeout: 5000, //5秒
          timeoutObj: null,
          reset: function() {
            clearInterval(this.timeoutObj);
            return this;
          },
          start: function() {
            this.timeoutObj = setInterval(function() {
            }, this.timeout);
          }
        };
        if ("WebSocket" in window) {
          _this.websocket = new WebSocket(_this.wsUrl);
        } else if ("MozWebSocket" in window) {
          _this.websocket = new MozWebSocket(_this.wsUrl);
        } else {
          _this.websocket = new SockJS(_this.wsUrl);
        }
        _this.websocket.onopen = function() {
          console.log("websock连接成功");
          heartCheck.reset().start();
        };
        _this.websocket.onmessage = function(event) {
          console.log("服务器回应" + event.data);
          if (event.data.code == "8888") {
            if (event.isPaySuccess) {
              _this.onClose()
              _this.$router.replace({
                path: "/successOrder",
                query: {
                  id: _this.$route.query.id
                }
              });
            }
          }
        };
        _this.websocket.onclose = function(data) {
          console.log("断开连接")
          heartCheck.reset();
        };
      }
    },
    onClose() {
      this.websocket.close();
      this.websocket = null;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.websocket) {
      this.websocket.close();
    }
  },
  created() {
    // this.webSocketLink();
  }
};
</script>

<style lang="less">
</style>
