<template>
  <div class="container_view">
    <van-nav-bar :fixed="true" class="van_nav_bar_view" left-arrow>
      <template #left>
        <!-- <router-link tag="img">aaa</router-link> -->
        <img
          style="width: 18px; height: 18px"
          src="../../../assets/black_return_view_bg.png"
          alt
          @click="$router.go(-1)"
        />
      </template>
      <template #title>
        <span style="color: black; font-size: 18px">确认订单</span>
      </template>
    </van-nav-bar>

    <!--收货地址区域-->
    <div class="consignee_view" @click="handelClick">
      <div class="user_info_view">
        <div>
          <span>收货人：{{ receipt.contactPerson }}</span>
          <span>{{ receipt.phone }}</span>
        </div>
        <van-icon name="arrow" color="#999999" />
      </div>
      <span class="address_view">{{ receipt.address }}</span>
    </div>

    <!--送达时间-->
    <div class="service_time_view" @click="showSwTime">
      <span class="time_view">送达时间</span>
      <div>
        <span>{{ isTimeSelectTitle }} {{ time_ }}</span>
        <van-icon name="arrow" color="#999999"></van-icon>
      </div>
    </div>

    <!--商品块-->
    <div class="goods_info_view" v-for="(item, i) in dataJson.carts || []" :key="i">
      <img :src="imgApp + item.spu && imgApp + item.spu.image" alt />
      <div class="info_view">
        <div class="title_view">
          <span class="name_view">{{ item.spu && item.spu.name }}</span>
          <div>
            <span>₱</span>
            <span>{{ item.price }}</span>
          </div>
        </div>
        <!-- 切块显示有问题 -->
        <span class="guige_view">{{ descs }}: {{ content }}</span>
        <div class="total_view">
          <span>₱</span>
          <span>{{ item.spu && item.sku.quote.price }}</span>
          <span>x{{ item.quantity }}</span>
        </div>
      </div>
    </div>
    <!--运费-->
    <div class="service_time_view" @click="showExpress = false">
      <span class="time_view">运费</span>
      <div>
        <span>
          <span v-if="freight">₱</span>
          <span>{{ freight || "包邮" }}</span>
        </span>
        <!-- <van-icon name="arrow" color="#999999"></van-icon> -->
      </div>
    </div>
    <!--优惠明细-->
    <!-- <div class="service_time_view">
      <span class="time_view">优惠明细</span>
      <div>
        <span style="color: #999999">优惠明细</span>
        <van-icon name="arrow" color="#999999"></van-icon>
      </div>
    </div>-->
    <!--买家留言-->
    <div class="message_view">
      <span>买家留言</span>
      <textarea v-model="note" placeholder="给买家留言"></textarea>
    </div>

    <div class="bottom_view">
      <div
        class="total_num_view_top"
        style="display: flex;
    justify-content: center;padding-left:0"
      >
        <p style="font-size: 12px; color: #3f3f3f">
          差
          <span style="font-size: 14px; color: #df3436">₱{{difference}}</span> 包邮
        </p>
        <!-- <label style="font-size: 12px; color: #3f3f3f">
          共
          <span style="font-size: 18px; color: #3f3f3f; font-weight: bold">
            {{
            dataJson.num
            }}
          </span>件
        </label>-->
      </div>
      <div class="total_num_view">
        <label style="font-size: 12px; color: #3f3f3f">
          共
          <span style="font-size: 18px; color: #3f3f3f; font-weight: bold">
            {{
            dataJson.num
            }}
          </span>件,
        </label>
        <div>
          <label style="font-size: 12px; color: #3f3f3f">
            优惠
            <span>₱</span>
            <span style="font-size: 18px; color: #3f3f3f; font-weight: bold">
              {{
              (dataJson &&
              dataJson.vouchers &&
              dataJson.vouchers[0] &&
              dataJson.vouchers[0].reduceAmount) ||
              0
              }}
            </span>
          </label>
        </div>
        <div @click="discountPopup" class="mx_view">优惠明细</div>
      </div>
      <div class="real_price_view">
        <label style="font-size: 12px; color: #3f3f3f">
          实付金额
          <span style="color: #df3436">₱</span>
          <span style="font-size: 18px; color: #df3436">
            {{
            (dataJson.priceAll + (freight || 0)) -
            ((dataJson &&
            dataJson.vouchers &&
            dataJson.vouchers[0] &&
            dataJson.vouchers[0].reduceAmount) ||
            0)
            }}
          </span>
        </label>
        <img src="../../../assets/exchange_rate_bg.png" alt />
        <label>
          <span style="color: #df3436; margin-left: 3px">¥</span>
          <span style="font-size: 18px; color: #df3436">
            {{
            (
            ((dataJson.priceAll + (freight || 0)) -
            ((dataJson &&
            dataJson.vouchers &&
            dataJson.vouchers[0] &&
            dataJson.vouchers[0].reduceAmount) ||
            0)) /
            dataJson.exchangeRate
            ).toFixed(2)
            }}
          </span>
          <span style="font-size: 10px; color: #3f3f3f">（¥1.00=₱{{ dataJson.exchangeRate }}）</span>
        </label>
      </div>
      <div class="btn_view">
        <van-button :disabled="showbtn" @click="popupWindow">提交订单</van-button>
      </div>
      <!--支付-->
      <van-action-sheet class="van_action_sheet_view" v-model="show" title="请选择支付方式">
        <div class="popup_info_view">
          <span style="font-size: 20px; color: #3f3f3f; margin-top: 20px">实付金额</span>
          <label>
            <span>₱</span>
            <span>{{ dataJson.priceAll + (freight || 0) }}</span>
          </label>
          <van-radio-group class="group_view" v-model="payRadio" checked-color="#00B05A">
            <div class="radio_view" @click="payischeck(1)">
              <div>
                <img src="../../../assets/alipay_bg.png" alt />
                <span>支付宝</span>
              </div>
              <van-radio class="radio_btn_view" :name="1"></van-radio>
            </div>
            <div class="radio_view" @click="payischeck(2)">
              <div>
                <img src="../../../assets/wechat_pay_bg.png" alt />
                <span>微信</span>
              </div>
              <van-radio class="radio_btn_view" :name="2"></van-radio>
            </div>
            <div class="radio_view" @click="payischeck(3)">
              <div>
                <img src="../../../assets/hdfk_bg.png" alt />
                <span>货到付款</span>
              </div>
              <van-radio class="radio_btn_view" :name="3"></van-radio>
            </div>
          </van-radio-group>
          <div class="btn_view">
            <van-button @click="selectPay">支付</van-button>
          </div>
        </div>
      </van-action-sheet>
      <!--送达时间-->
      <van-action-sheet class="van_action_sheet_view" v-model="showTime" title="请选择预计送达时间">
        <div class="sdsj_view">
          <div class="sdsj_left_view">
            <div
              v-if="earliest == '今天'"
              :class="
                choiceTime === todayTime
                  ? 'is_select_class_view'
                  : 'is_no_select_class_view'
              "
              @click="selectTime(todayTime)"
            >{{ todayTime }}</div>
            <div
              v-if="earliest == '今天' || earliest == '明天'"
              :class="
                choiceTime === tomorrowTime
                  ? 'is_select_class_view'
                  : 'is_no_select_class_view'
              "
              @click="selectTime(tomorrowTime)"
            >{{ tomorrowTime }}</div>
            <div
              v-if="
                earliest == '今天' || earliest == '明天' || earliest == '后天'
              "
              :class="
                choiceTime === afterTime
                  ? 'is_select_class_view'
                  : 'is_no_select_class_view'
              "
              @click="selectTime(afterTime)"
            >{{ afterTime }}</div>
          </div>
          <div style="width: 65%">
            <div
              class="sdsj_right_view"
              v-for="(item, index) in list"
              :key="index"
              @click="handelDblclick(index)"
            >
              <van-radio-group class="group_view" v-model="radio" checked-color="#00B05A">
                <div
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                  "
                  @click="newIsCheck(index)"
                >
                  <span
                    @click="isCheck(index)"
                    :class="
                      radio === index ? 'green_color_view' : 'balck_color_view'
                    "
                  >{{ item }}</span>
                  <van-radio
                    :class="
                      radio === index
                        ? 'radio_select_view'
                        : 'radio_no_select_view'
                    "
                    icon-size="20px"
                    style="margin-left: 20px"
                    :name="index"
                  ></van-radio>
                </div>
              </van-radio-group>
            </div>
          </div>
        </div>
      </van-action-sheet>
      <!--优惠明细-->
      <van-action-sheet class="van_action_sheet_view" v-model="showYh" title="优惠明细">
        <div class="discount_view">
          <div>
            <span>商品总价</span>
            <label>
              <span>₱</span>
              <span>{{ dataJson.priceAll }}</span>
            </label>
          </div>
          <div>
            <span>运费{{ delivery }}</span>
            <label>
              <span>₱</span>
              <span>{{ freight || 0 }}</span>
            </label>
          </div>
          <div>
            <span>实付金额</span>
            <label>
              <span>₱</span>
              <span>{{ dataJson.priceAll + (freight || 0) }}</span>
            </label>
          </div>
          <div class="discount_bottom_view">
            <div>支付</div>
          </div>
        </div>
      </van-action-sheet>
      <!--选择快递-->
      <van-action-sheet class="van_action_sheet_view" v-model="showExpress" title="选择配送方式">
        <div class="kd_view">
          <div class="ps_view" v-for="(item, i) in dataJson.platform || []" :key="i">
            <div
              :class="item.isSelectBtn ? 'pt_view' : 'other_view'"
              @click="handlerClick(item)"
            >{{ item.delivery }}</div>
            <!-- <div
              :class="!isSelectBtn ? 'pt_view' : 'other_view'"
              @click="isSelectBtn = false"
            >
              第三方配送
            </div>-->
          </div>
          <div class="freight_price_view">
            <span>运费</span>
            <label>
              <span>₱</span>
              <span>{{ freight2 || 0 }}</span>
            </label>
          </div>
          <div class="discount_bottom_view" @click="handelAccomplish">
            <div>完成</div>
          </div>
        </div>
      </van-action-sheet>
    </div>
    <van-dialog v-model="code_show" title="扫码支付" show-cancel-button @close="closeEvent">
      <img style="width: 100%" :src="wxCode" />
    </van-dialog>
    <webSocket ref="webSockt"></webSocket>
  </div>
</template>

<script>
import Vue from "vue";
import { Toast } from "vant";
import {
  getBusiness,
  getExchange,
  imgApp,
  getBySPu,
  getDefault,
  order_toSettle,
  arrive_timer,
  set_Exchange,
  reach_cash,
  order_determine,
  order_onceGenerateOrder,
  pay_createOrderAli,
  pay_AliPayH5,
  pay_WxPayH5,
  AlipayWebH5,
  WxWebPayH5,
  pay_recharge_callback,
  getFreightByReceiptId,
  getTimeByIds,
  generateCartOrder, //购物车下单
  getDeliveryTime
} from "@/service/api/index.js";
import webSocket from "@/components/webSocket";

export default {
  components: {
    webSocket
  },
  data() {
    return {
      wxCode: "",
      userAgent: "",
      note: "",
      receipt: {},
      spu: {},
      sku: {},
      dataJson: {},
      imgApp,
      exchange: "",
      radio: "",
      payRadio: "",
      freight: 0,
      freight2: 0,
      delivery: "",
      isDefault: true,
      show: false,
      code_show: false,
      showYh: false,
      showTime: false,
      showExpress: false,
      isSelectBtn: true,
      isTimeSelect1: 1,
      isTimeSelect2: 2,
      isTimeSelect3: 3,
      todayTime: "",
      tomorrowTime: "",
      afterTime: "",
      activeId: 1,
      activeIndex: 0,
      orderId: "",
      amount: "",
      payType: "",
      body: "",
      sn: "",
      items: [{ text: "今天" }, { text: "明天" }, { text: "后天" }],
      list: [],
      earliestTime: "",
      startTime: "",
      endTime: "",
      choiceTime: "",
      allTime: [],
      startEarliest: "",
      i: 0,
      h: 0,
      btnDisabled: true,
      freightId: "",
      showbtn: false,
      consum: ""
    };
  },
  created() {
    this.getDate();
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      this.userAgent = "IOS";
    } else if (/(Android)/i.test(navigator.userAgent)) {
      this.userAgent = "这是Android";
    } else {
      this.userAgent = "PC";
    }
  },
  computed: {
    difference: {
      get() {
        if (this.consum > this.dataJson.priceAll) {
          return Number(this.consum) - Number(this.dataJson.priceAll);
        } else {
          return "0.0";
        }
      }
    },
    earliest: {
      get() {
        if (this.todayTime == this.earliestTime) {
          return "今天";
        }
        if (this.tomorrowTime == this.earliestTime) {
          return "明天";
        }
        if (this.afterTime == this.earliestTime) {
          return "后天";
        }
      }
    },
    isTimeSelectTitle: {
      get() {
        if (this.todayTime == this.choiceTime) {
          return "今天";
        }
        if (this.tomorrowTime == this.choiceTime) {
          return "明天";
        }
        if (this.afterTime == this.choiceTime) {
          return "后天";
        }
      }
    },
    time_: {
      get() {
        if (this.list[this.radio || 0]) {
          return this.list[this.radio || 0];
        }
        return "";
      }
    },
    descs: {
      get() {
        return this.$route.query.descs;
      }
    },
    content: {
      get() {
        return this.$route.query.content;
      }
    }
  },
  mounted() {
    if (this.$route.query.page === "cart") {
      const formData = new FormData();
      formData.append("cartIds", this.$route.query.cartIds);
      order_toSettle(formData)
        .then(res => {
          if (res.status === 200) {
            if (this.$route.query.address) {
              this.receipt = Object.assign({}, res.data.receipt, {
                address: this.$route.query.address,
                contactPerson: this.$route.query.contactPerson,
                houseNumber: this.$route.query.houseNumber,
                phone: this.$route.query.phone,
                ressId: this.$route.query.ressId
              });
            } else {
              this.receipt = res.data.receipt || {};
            }
            this.dataJson = res.data;
            if (this.receipt.ressId) {
              this.dataJson.receipt.id = this.receipt.ressId;
            }
            //请求运费模板
            getFreightByReceiptId({
              receiptId: this.dataJson.receipt.id
            }).then(res => {
              if (res.status == 200) {
                this.freightId = res.data.platform[0].id;
                //消费金额
                this.consum = res.data.platform[0].consum;
                //如果消费金额大于总价，才计算运费
                if (this.consum > this.dataJson.priceAll) {
                  this.freight = res.data.platform[0].freight;
                }
              } else {
                Toast("订单超出配送范围！");
                this.showbtn = true;
              }
            });

            // this.sku = res.data.carts[0].sku;
            // this.sku = res.data.carts;
            this.arrive_timer_();
          }
        })
        .catch(err => {});
    } else if (this.$route.query.page === "goods") {
      order_determine({
        num: this.$route.query.num,
        spuId: this.$route.query.id,
        specIds: this.$route.query.specIds
      })
        .then(res => {
          if (res.status === 200) {
            if (this.$route.query.address) {
              this.receipt = Object.assign({}, res.data.receipt, {
                address: this.$route.query.address,
                contactPerson: this.$route.query.contactPerson,
                houseNumber: this.$route.query.houseNumber,
                phone: this.$route.query.phone,
                ressId: this.$route.query.ressId
              });
            } else {
              const { data = {} } = res || {};
              this.receipt = data.receipt || {};
            }
            this.dataJson = res.data;
            if (this.receipt.ressId) {
              this.dataJson.receipt.id = this.receipt.ressId;
            }
            //请求运费模板
            getFreightByReceiptId({
              receiptId: this.dataJson.receipt.id
            }).then(res => {
              if (res.status == 200) {
                this.freightId = res.data.platform[0].id;
                //消费金额
                this.consum = res.data.platform[0].consum;
                //如果消费金额大于总价，才计算运费
                // console.log(this.consum,this.dataJson.priceAll)
                if (this.consum > this.dataJson.priceAll) {
                  this.freight = res.data.platform[0].freight;
                }
              } else {
                Toast("订单超出配送范围！");
                this.showbtn = true;
              }
            });
            // this.sku = res.data.carts[0].sku;
            // this.sku = res.data.carts;

            this.arrive_timer_();
          } else {
          }
        })
        .catch(err => {});
    }
  },
  methods: {
    getDate() {
      var dateTime = new Date();
      const y = dateTime.getFullYear();
      const m = (dateTime.getMonth() + 1 + "").padStart(2, "0");
      const d = (dateTime.getDate() + "").padStart(2, "0");
      this.todayTime = y + "-" + m + "-" + d;
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      const y2 = dateTime.getFullYear();
      const m2 = (dateTime.getMonth() + 1 + "").padStart(2, "0");
      const d2 = (dateTime.getDate() + "").padStart(2, "0");
      var youWant = y2 + "-" + m2 + "-" + d2;
      this.tomorrowTime = youWant;
      dateTime = dateTime.setDate(dateTime.getDate() + 1);
      dateTime = new Date(dateTime);
      const y3 = dateTime.getFullYear();
      const m3 = (dateTime.getMonth() + 1 + "").padStart(2, "0");
      const d3 = (dateTime.getDate() + "").padStart(2, "0");
      var youWant1 = y3 + "-" + m3 + "-" + d3;
      this.afterTime = youWant1;
    },
    handelClick() {
      // this.$router.push({
      this.$router.replace({
        path: "/address",
        query: Object.assign(this.$route.query, {
          type: "1"
        })
      });
    },
    handlerClick(item) {
      for (const el of this.dataJson.platform) {
        this.$set(el, "isSelectBtn", false);
      }
      this.$set(item, "isSelectBtn", true);
      this.freight2 = item.freight || 0;
    },
    handelAccomplish() {
      const [item = {}] = this.dataJson.platform.filter(e => e.isSelectBtn);
      this.freight = item.freight || 0;
      this.delivery = item.delivery;
      this.showExpress = false;
    },
    popupWindow() {
      this.toPay();
    },
    discountPopup() {
      this.showYh = true;
    },
    showSwTime() {
      this.showTime = true;
    },
    isCheck(e) {
      if (e === 1) {
        this.radio = 1;
        // this.payType = "ALIPAY";
      } else if (e === 2) {
        this.radio = 2;
        // this.payType = "WXPAY";
      } else if (e === 3) {
        this.radio = 3;
        // this.payType = "HUO";
      }
    },
    payischeck(e) {
      if (e === 1) {
        this.payRadio = 1;
        this.payType = "ALIPAY";
      } else if (e === 2) {
        this.payRadio = 2;
        this.payType = "WXPAY";
      } else if (e === 3) {
        this.payRadio = 3;
        this.payType = "HUO";
      }
    },
    newIsCheck(e) {
      this.radio = e;
    },
    handelDblclick(e) {
      // console.log("handelDblclick",e)

      this.i++;
      setTimeout(() => {
        this.i = 0;
      }, 500);
      if (this.i > 1) {
        this.showTime = false;
        this.i = 0;
      }
    },
    toPay() {
      // receipt: 收获地址, 不存在时则未设置收获地址
      if (!this.dataJson.receipt || !this.dataJson.receipt.id) {
        Toast("未设置收货地址");
        return;
      }
      if (this.$route.query.page == "goods") {
        var time = this.choiceTime;
        const formData = new FormData();
        this.payType =
          this.payRadio == 1
            ? "ALIPAY"
            : this.payRadio == 2
            ? "WXPAY"
            : this.payRadio == 3
            ? ""
            : "";
        formData.append("freightId", this.freightId);
        formData.append("note", this.note);
        formData.append("payType", "WXPAY");
        formData.append("quantity", this.dataJson.num);
        formData.append("receiptId", this.dataJson.receipt.id);
        formData.append("specIds", this.dataJson.carts[0].specs[0].id);
        formData.append("spuId", this.dataJson.carts[0].spu.id);
        formData.append("voucherId", "");
        const n = parseInt(this.time_.split("-")[0]);
        if (n % 2 !== 0) {
          formData.append(
            "time",
            (time + " " + (n + 1 + "").padStart(2, "0") + ":00").trim()
          );
        } else {
          formData.append(
            "time",
            (time + " " + this.time_.split("-")[0]).trim()
          );
        }
        order_onceGenerateOrder(formData).then(res => {
          if (res.status == 200) {
            this.orderId = res.data.orderId;
            this.sn = res.data.sn;
            this.amount = this.dataJson.num;
            this.body = this.dataJson.carts[0].spu.name;
            this.show = true;
          } else {
            return Toast(res.message);
          }
        }).catch(err=>{
          if(err.response.data.message == "没有运费模版"){
            Toast("收货地址超出配送范围！");
          }
        });
      } else {
        var time = this.choiceTime;
        this.payType =
          this.payRadio == 1
            ? "ALIPAY"
            : this.payRadio == 2
            ? "WXPAY"
            : this.payRadio == 3
            ? ""
            : "";

        var data = {
          cartIds: this.$route.query.cartIds,
          payType: "WXPAY",
          freightId: this.freightId,
          time: "",
          receiptId: this.dataJson.receipt.id
        };
        const n = parseInt(this.time_.split("-")[0]);
        if (n % 2 !== 0) {
          data.time = (
            time +
            " " +
            (n + 1 + "").padStart(2, "0") +
            ":00"
          ).trim();
        } else {
          data.time = (time + " " + this.time_.split("-")[0]).trim();
        }
        generateCartOrder(data).then(res => {
          if (res.status == 200) {
            this.orderId = res.data.orderId;
            this.sn = res.data.sn;
            this.amount = this.dataJson.num;
            this.body = this.dataJson.carts[0].spu.name;
            this.show = true;
          } else {
            return Toast(res.message);
          }
        }).catch(err=>{
          if(err.response.data.message == "没有运费模版"){
            Toast("收货地址超出配送范围！");
          }
        });
      }
    },
    selectPay() {
      if (this.payType == "ALIPAY" && this.userAgent == "PC") {
        // 电脑支付宝支付
        const priceAll = this.dataJson.priceAll;
        const reduceAmount =
          (this.dataJson.vouchers &&
            this.dataJson.vouchers[0] &&
            this.dataJson.vouchers[0].reduceAmount) ||
          0;
        const exchangeRate = this.dataJson.exchangeRate;
        AlipayWebH5({
          sn: this.sn,
          notifyUrl: `${window.location.protocol}//${window.location.host}/successOrder`
        }).then(res => {
          localStorage.setItem("dom", res.data);
          this.$router.push("zf");
        });
      } else if (this.payType === "ALIPAY") {
        // 手机支付宝支付
        pay_AliPayH5({
          sn: this.sn,
          returnUrl: `${window.location.protocol}//${window.location.host}/successOrder`,
          quitUrl: `${window.location.protocol}//${window.location.host}/home`
        }).then(res => {
          if (res.status == 200) {
            localStorage.setItem("dom", res.data);
            this.$router.push("zf");
          } else {
            return Toast(res.message);
          }
        });
      } else if (this.payType == "WXPAY" && this.userAgent == "PC") {
        // 电脑微信支付
        WxWebPayH5({
          sn: this.sn
        }).then(res => {
          this.wxCode = res.data;
          this.code_show = true;
          this.$refs["webSockt"].webSocketLink();
        });
      } else if (this.payType === "WXPAY") {
        // 手机微信支付
        pay_WxPayH5({
          sn: this.sn
        }).then(res => {
          window.location.href =
            res.data +
            `&redirect_url=` +
            encodeURIComponent(
              `${window.location.protocol}//${window.location.host}/successOrder`
            );
        });
      } else if (this.payType === "HUO") {
        // 货到付款
        const formData = new FormData();
        formData.append("orderId", this.orderId);
        reach_cash(formData).then(res => {
          if (res.status == 200) {
            this.$router.push({
              path: "/successOrder",
              query: {
                id: this.$route.query.id
              }
            });
          } else {
            Toast(res.message);
          }
        });
      }
    },
    arrive_timer_() {
      let req = [];
      for (let item of this.dataJson.carts) {
        req.push({
          id: item.sku.id,
          num: item.quantity
        });
      }
      // 新计算时间接口
      getDeliveryTime({
        data: req,
        params: { deliveryTime: "" }
      }).then(res => {
        this.allTime = res.data;
        if (
          this.allTime.dayAfterTomorrow != null &&
          this.allTime.tomorrow != null &&
          this.allTime.today != null
        ) {
          this.earliestTime = this.allTime.today.date;
          this.choiceTime = this.allTime.today.date;
        } else if (
          this.allTime.dayAfterTomorrow != null &&
          this.allTime.tomorrow != null
        ) {
          this.earliestTime = this.allTime.tomorrow.date;
          this.choiceTime = this.allTime.tomorrow.date;
        } else if (this.allTime.dayAfterTomorrow != null) {
          this.earliestTime = this.allTime.dayAfterTomorrow.date;
          this.choiceTime = this.allTime.dayAfterTomorrow.date;
        }
      });
    },
    selectTime(val) {
      this.choiceTime = val;
    },
    closeEvent() {
      this.$refs["webSockt"].onClose();
    }
  },
  watch: {
    choiceTime: {
      handler(active) {
        for (let i in this.allTime) {
          if (this.allTime[i]) {
            if (active == this.allTime[i].date) {
              this.list = this.allTime[i].time;
            }
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
@media screen and (min-width: 768px) {
  .container_view {
    max-width: 540px;
    background-color: #f1f5f6;
  }
  body {
    max-width: 540px;
  }
  .van_nav_bar_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .van_action_sheet_view {
    max-width: 540px;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  .ps_view {
    max-width: 540px;
  }
}
.container_view {
  background-color: #f1f5f6;
}
.balck_color_view {
  font-size: 14px;
  color: #3f3f3f;
  display: block;
}
.green_color_view {
  font-size: 14px;
  display: block;
  color: #00b05a;
}
.radio_no_select_view {
  opacity: 0;
}
.radio_select_view {
  opacity: 1;
}

.consignee_view {
  max-width: 540px;
  width: 100%;
  height: 84px;
  margin-top: 44px;
  background: white;
  .user_info_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 9px;
    div {
      span:first-of-type {
        font-size: 16px;
        color: #3f3f3f;
      }
      span:last-of-type {
        font-size: 14px;
        color: #999999;
        margin-left: 20px;
      }
    }
  }
  .address_view {
    width: 300px;
    display: block;
    font-size: 14px;
    color: #3f3f3f;
    margin-left: 15px;
    margin-top: 5px;
  }
}
.service_time_view {
  width: 100%;
  height: 46px;
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  .time_view {
    font-size: 14px;
    color: #3f3f3f;
    margin-left: 12px;
  }
  div {
    margin-right: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    span {
      color: #00b05a;
      span {
        color: #999999;
        margin-left: 5px;
      }
    }
  }
}
.goods_info_view {
  width: 100%;
  height: 120px;
  background: white;
  // padding-top: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 74px;
    height: 74px;
    margin-left: 6px;
    margin-right: 10px;
  }
  .info_view {
    display: flex;
    flex-direction: column;
    .title_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .name_view {
        font-size: 13px;
        color: #3f3f3f;
      }
      div {
        span:first-of-type {
          color: #df3436;
          font-size: 12px;
        }
        span:last-of-type {
          color: #df3436;
          font-size: 18px;
        }
      }
    }
    .guige_view {
      margin-top: 5px;
      font-size: 12px;
      color: #999999;
    }
    .total_view {
      margin-top: 10px;
      span:nth-child(2) {
        font-size: 18px;
        color: #3f3f3f;
        font-weight: bold;
      }
      span:nth-child(3) {
        font-size: 14px;
        color: #3f3f3f;
        margin-left: 5px;
      }
    }
  }
}
.message_view {
  width: 100%;
  height: 120px;
  background: white;
  display: flex;
  // position: absolute;
  // bottom: 40px;
  margin-top: 10px;
  flex-direction: column;
  padding-bottom: 125px;
  span {
    font-size: 14px;
    color: #3f3f3f;
    margin-left: 12px;
    margin-top: 12px;
    margin: 12px 0px 14px 12px;
  }
  textarea {
    width: 300px;
    height: 60px;
    margin-right: 30px;
    margin-left: 12px;
    background: white;
    font-size: 13px;
    border: none;
  }
}
.bottom_view {
  max-width: 540px;
  width: 100%;
  // height: 120px;
  background: white;
  position: fixed;
  bottom: 0;
  .total_num_view_top {
    max-width: 540px;
    width: 98%;
    height: 31px;
    background: #fff9ed;
    display: flex;
    align-items: center;
    padding-left: 12px;
    border-bottom: 1px solid #f2f2f2;
    flex-direction: row;
  }
  .total_num_view {
    max-width: 540px;
    width: 98%;
    height: 31px;
    background: white;
    display: flex;
    align-items: center;
    padding-left: 12px;
    border-bottom: 1px solid #f2f2f2;
    flex-direction: row;
    .mx_view {
      width: 50px;
      height: 15px;
      background: #ffeaea;
      border-radius: 7px;
      font-size: 5px;
      line-height: 15px;
      text-align: center;
      margin-left: 5px;
      color: #df3436;
      border-bottom: 1px solid #f2f2f2;
    }
  }
  .real_price_view {
    width: 98%;
    height: 31px;
    background: white;
    padding-left: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 14px;
      height: 14px;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
  .btn_view {
    max-width: 540px;
    width: 100%;
    height: 57px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 100%;
      height: 36px;
      margin-left: 20px;
      margin-right: 20px;
      background: linear-gradient(to right, #01b15b, #49d0a2);
      border-radius: 18px;
      border: none;
      color: white;
      font-size: 13px;
    }
  }
}
.popup_info_view {
  width: 100%;
  height: 337px;
  background: white;
  display: flex;
  text-align: center;
  border-top: 0.5px solid #eeeeee;
  flex-direction: column;
  label {
    margin-top: 11px;
    margin-bottom: 40px;
    span:first-of-type {
      font-size: 25px;
      color: #df3436;
    }
    span:last-of-type {
      font-size: 37px;
      color: #df3436;
    }
  }

  .radio_view {
    width: 100%;
    height: 46px;
    display: flex;
    background: white;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 12px;
      img {
        width: 34px;
        height: 34px;
      }
      span {
        display: block;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3f3f3f;
        margin-left: 17px;
      }
    }
    .radio_btn_view {
      margin-right: 12px;
    }
  }
}
.sdsj_view {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  .sdsj_left_view {
    width: 35%;
    background: #f1f5f6;
    display: flex;
    flex-direction: column;
    .is_select_class_view {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: #00b05a;
      justify-content: center;
      background: white;
    }
    .is_no_select_class_view {
      width: 100%;
      height: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 14px;
      color: #333333;
      border-radius: 0px 8px 0px 0px;
      justify-content: center;
      background: #f1f5f6;
    }
  }
  .sdsj_right_view {
    width: 65%;
    height: 50px;
    font-size: 14;
    padding-left: 30px;
    color: #00b05a;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
  }
}
.discount_view {
  width: 100%;
  display: flex;
  height: 300px;
  flex-direction: column;
  div:nth-child(1) {
    width: 100%;
    height: 44px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span:first-of-type {
      font-size: 12px;
      color: #3f3f3f;
      margin-left: 12px;
    }
    label {
      margin-right: 12px;
      span:first-of-type {
        font-size: 10px;
        font-weight: bold;
        color: #3f3f3f;
      }
      span:last-of-type {
        font-size: 14px;
        color: #3f3f3f;
        font-weight: bold;
      }
    }
  }
  div:nth-child(2) {
    width: 100%;
    height: 44px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span:first-of-type {
      font-size: 12px;
      color: #3f3f3f;
      margin-left: 12px;
    }
    label {
      margin-right: 12px;
      span:first-of-type {
        font-size: 10px;
        color: #3f3f3f;
        font-weight: bold;
      }
      span:last-of-type {
        font-size: 14px;
        color: #3f3f3f;
        font-weight: bold;
      }
    }
  }
  div:nth-child(3) {
    width: 100%;
    height: 44px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span:first-of-type {
      font-size: 14px;
      color: black;
      margin-left: 12px;
      font-weight: bold;
    }
    label {
      margin-right: 12px;
      span:first-of-type {
        font-size: 10px;
        color: #3f3f3f;
        font-weight: bold;
      }
      span:last-of-type {
        font-size: 14px;
        color: #3f3f3f;
        font-weight: bold;
      }
    }
  }
  .discount_bottom_view {
    width: 100%;
    height: 57px;
    position: fixed;
    bottom: 0;
    border-top: 0.5px solid #ebebeb;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 100%;
      height: 36px;
      line-height: 36px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      color: white;
      font-size: 13px;
      text-align: center;
      border-radius: 18px;
      background: linear-gradient(to right, #01b15b, #49d0a2);
    }
  }
}
.kd_view {
  height: 300px;
  .ps_view {
    width: 100%;
    height: 72px;
    background: white;
    display: flex;
    flex-direction: row;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
    .pt_view {
      width: 82px;
      height: 33px;
      background: rgba(0, 176, 90, 0.1);
      border-radius: 4px;
      text-align: center;
      line-height: 33px;
      border: 1px solid #00b05a;
      font-size: 13px;
      margin-left: 12px;
      margin-right: 20px;
      color: #00b05a;
    }
    .other_view {
      width: 82px;
      height: 33px;
      background: #f2f2f2;
      border-radius: 4px;
      text-align: center;
      line-height: 33px;
      font-size: 13px;
      margin-left: 12px;
      margin-right: 20px;
      border: 1px solid #f2f2f2;
      color: black;
    }
  }
  .freight_price_view {
    width: 100%;
    height: 35px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    span:nth-child(1) {
      font-size: 12px;
      color: #3f3f3f;
      margin-left: 12px;
    }
    label {
      margin-right: 20px;
      span:first-of-type {
        font-size: 10px;
        color: #3f3f3f;
      }
      span:last-of-type {
        font-size: 16px;
        color: #3f3f3f;
      }
    }
  }
  .discount_bottom_view {
    width: 100%;
    height: 57px;
    position: fixed;
    bottom: 0;
    border-top: 0.5px solid #ebebeb;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 100%;
      height: 36px;
      line-height: 36px;
      margin-left: 20px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      color: white;
      font-size: 13px;
      text-align: center;
      border-radius: 18px;
      background: linear-gradient(to right, #01b15b, #49d0a2);
    }
  }
}
</style>
